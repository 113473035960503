@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$font-default: 'Karla', Arial, Helvetica, sans-serif;

* {
  -webkit-font-smoothing: antialiased;
  font-family: $font-default;
  font-smoothing: antialiased;
  font-weight: 400; }

h1, h2, h3, h4, h5, h6 {
  color: $font-black;
  font-family: $font-default; }

.description-title {
  font-size: 1.5em;
  text-transform: capitalize; }

.paragraph {
  color: $font-black;
  font-size: 1.2em;
  line-height: 1.6em;
  margin: 1em 0; }

a {
  color: $black;
  text-decoration: none; }

b {
  font-weight: 700; }

h1 {
  font-size: 2.5em;
  margin: 0;
  line-height: 1.3em;
  @media screen and (max-width: $mobile) {
    font-size: 2em; } }

h2 {
  font-size: 2.5em;
  margin-bottom: .5em;
  @media screen and (max-width: $mobile) {
    font-size: 1.5em; } }

p, ul li, a {
  color: $font-black;
  font-size: 1.2rem;
  line-height: 1.6em; }

.section-title {
  color: $font-gray;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  &-logo {
    @extend .section-title;
    color: $black; } }
