footer {
    @extend .container;
    text-align: center;
    margin: 4em auto 2em;

    .paragraph {
        margin: 2em; }

    .footer-social-link {
        border-bottom: 1px solid $black;
        display: inline-block;
        font-size: 1em;
        margin: 0 1em 2em; }

    .bye {
        font-size: .85em;
        margin-top: 1em; } }
