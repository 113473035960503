.blog {
    @extend .container;
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &-wrap {
        height: 15em;
        margin-bottom: 1em;
        width: 49%;

        .access {
            background: red;
            display: flex;
            height: 100%; } }

    &-description {
        align-self: flex-end;
        background: white;
        display: flex;
        flex-flow: column;
        padding: 2em;
        width: 60%; } }
