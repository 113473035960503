//main colours
$black: #303030;
$black-pure: #000;
$yellow: #FFD965;
$mustard: #FFC107;

// font colours
$font-black: #4F4F4F;
$font-gray: #828282;
$font-white: rgba(255,255,255,.90);

// background colours
$bg-light: rgba(0,0,0,.05);
$bg-dark: rgba(0,0,0,.75);
$bg-light-blue: rgba(77, 113, 158, 0.05);

//responsive
$mobile: 750px;
$tablet: 1099px;
$desktop: 1100px;
