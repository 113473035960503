* {
  margin: 0;
  padding: 0; }

.container {
  margin: 0 auto;
  max-width: calc(100% - 100px);
  width: calc(1280px - 180px);

  @media screen and (max-width: $mobile) {
    max-width: calc(100% - 40px);
    margin: 2em auto; } }

.block-space {
  padding-top: 5em;
  @media screen and (max-width: $mobile) {
    padding-top: 2em; } }
