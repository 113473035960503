.side-projects {
    @extend .container;
    @extend .block-space;

    &__block {
      background: $bg-light;
      padding: 3em; }

    .description-title {
      margin-bottom: 1em; }

    .cta-underline {
      display: block;
      margin-top: 1em; } }
