.banner {
  @extend .container;
  margin: 6em auto 8em;
  @media screen and (max-width: $mobile) {
    margin: 4em auto; }

  h1 {
    margin-bottom: 2rem;
    width: 65%;
    @media screen and (max-width: $tablet) {
      width: 80%; }
    @media screen and (max-width: $mobile) {
      width: 100%; } } }

