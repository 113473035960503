.portfolio {
  @extend .container;

  &-wrap {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

  &-impulso {
    background: url('/assets/images/portfolio/thumb/portfolio-img-impulso.png'); }

  &-smartguest {
    background: url('/assets/images/portfolio/thumb/portfolio-img-smartguest.png'); }

  &-leadwise {
    background: url('/assets/images/portfolio/thumb/portfolio-img-leadwise.png'); }

  &-boletosimples {
    background: url('/assets/images/portfolio/thumb/portfolio-img-boleto-simples.png'); }

  &-img {
    background-color: $mustard;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    height: 35em;
    margin-bottom: 1.5em;
    width: 49%;
    @media screen and (max-width: $tablet) {
      width: 100%; }

    .access {
      display: flex;
      height: 100%; } }

  &-description {
    align-self: flex-start;
    display: flex;
    flex-flow: column;
    margin: 20px auto;
    padding: 2em;
    width: 80%;

    @media screen and (max-width: $mobile) {
      width: 65%; }

    p {
      font-size: 1em;
      margin-top: 1em; } } }

.portfolio-internal {
  @extend .container;
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &-full,
  &-col {
    margin: 2em 0; }

  &-full {
    display: block;
    width: 100%; }

  &-col {
    width: 45%;
    @media screen and (max-width: $mobile) {
      width: 100%; } }

  &-img {
    width: 100%; } }
